@import "../assets/scss/variables.scss";
@import "../assets/scss/respond.scss";
.News {
  background-image: linear-gradient(#ebc3b1, #adbcd3);
  min-height: 100vh;
  padding: 200px;
  position: relative;
  @include respond(1600) {
    padding: 200px 15px;
  }
  > img {
    position: absolute;
    top: -160px;
    right: 50%;
    transform: translateX(50%);
    width: 350px;
  }
  .container {
    background-color: #fff;
    border-radius: 20px;
    height: 100%;
    padding: 30px;
    display: block !important;
    height: auto !important;
    min-height: unset !important;
    .new-wrapper {
      padding: 30px 0;
      border-bottom: 1px solid $blue;
      &:first-child {
        border-top: 1px solid $blue;
      }
      .new {
        border-right: 1px solid $blue;
        border-left: 1px solid $blue;
        display: flex;
        @include respond(768) {
          flex-wrap: wrap;
        }
        .data {
          width: 10%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-right: 1px solid $blue;
          font-size: 18px;
          font-weight: 700;
          color: $blue;
          @include respond(1200) {
            width: 15%;
          }
          @include respond(768) {
            display: none;
          }
        }
        .content {
          padding: 0 20px;
          width: 90%;
          display: flex;
          justify-content: center;
          flex-direction: column;
          @include respond(1200) {
            width: 85%;
          }
          @include respond(768) {
            text-align: center;
            width: 100%;
          }
          .title {
            color: $blue;
            margin: 0;
            font-size: 30px;
            font-weight: 700;
            margin-bottom: 10px;
            @include respond(768) {
              margin: 0;
            }
            @include respond(500) {
              font-size: 22px;
            }
          }
          .text {
            p {
              margin: 0;
              font-size: 18px;
              color: #000;
            }
            a {
              color: $blue;
            }
          }
          .data {
            display: none;
            @include respond(768) {
              display: block;
              border: none;
              text-align: center;
              width: unset;
              font-size: 18px;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}
