@import "../assets/scss/variables.scss";
.Preloader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    width: 200px;
  }
  .text {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    h1 {
      color: $blue;
      margin-bottom: 0;
      line-height: 1;
    }
    div {
      width: 5px;
      height: 5px;
      background-color: $blue;
      border-radius: 50%;
      margin-left: 6px;
      animation: sk-bouncedelay 1.8s infinite ease-in-out both;
      margin-bottom: 6px;
    }
    .bounce1 {
      animation-delay: -0.32s;
    }
    .bounce2 {
      animation-delay: -0.16s;
    }
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
