@import "../assets/scss/variables.scss";
@import "../assets/scss/respond.scss";
.Main {
  min-height: calc(100vh - 195px);
  .top {
    background-image: url("../assets/images/hp-bg.png");
    background-size: cover;
    height: calc(100vh - 75px);
    .content {
      height: 100%;
      width: 40%;
      padding: 140px 75px 0px 75px;
      text-align: center;
      @include respond(1800) {
        padding: 140px 30px 0px 75px;
      }
      @include respond(1550) {
        width: 43%;
      }
      @include respond(1300) {
        width: 50%;
      }
      @include respond(1100) {
        width: 60%;
      }
      @include respond(992) {
        width: 70%;
      }
      @include respond(768) {
        width: 100%;
        padding: 140px 75px 0px 75px;
      }
      @include respond(550) {
        padding: 140px 25px 0px 25px;
      }
      .title {
        font-size: 60px;
        color: $blue;
        margin: 0;
        @include respond(1450) {
          font-size: 50px;
        }
        @include respond(650) {
          font-size: 40px;
        }
      }
      .sub-title {
        font-size: 34px;
        font-weight: 300;
        color: $blue;
        margin: 0;
        margin-bottom: 60px;
        @include respond(1300) {
          font-size: 28px;
        }
        @include respond(650) {
          font-size: 22px;
        }
      }
      .btn {
        display: block;
        text-align: center;
        color: #fff;
        text-decoration: none;
        font-size: 20px;
        border-radius: 30px;
        padding: 10px;
        transition: background ease 0.3s;
        margin-bottom: 25px;
        font-weight: 300;
        &.btn-blue {
          background-color: $blue;
          &:hover {
            background-color: $blue-hover;
          }
        }
        &.btn-orange {
          background-color: $orange;
          &:hover {
            background-color: $orange-hover;
          }
        }
        &.btn-gray {
          background-color: $gray;
          &:hover {
            background-color: $gray-hover;
          }
        }
      }
      .to-news {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        h3 {
          font-size: 32px;
          color: #fff;
          margin: 10px;
        }
      }
    }
  }
}
