@import "../assets/scss/variables.scss";
.Register {
  min-height: calc(100vh - 195px);
  background-image: linear-gradient(#ebc3b1, #adbcd3);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Heebo";
  .content {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
    border-radius: 30px;
    padding: 50px;
    &.register-part {
      width: 50%;
      h1 {
        width: 100%;
        margin-bottom: 5px;
      }
      p {
        width: 100%;
        margin: 0;
        margin-bottom: 20px;
        color: #5a5a5a;
        font-size: 14px;
        text-align: center;
      }
      form {
        display: flex;
        flex-wrap: wrap;
        .col {
          width: 50%;
          padding: 0 10px;
        }
        .btn {
          margin: 20px 10px 0 10px;
        }
        .loader-wrapper {
          margin: auto;
          margin-top: 20px;
        }
      }
    }
    &.login-part {
      width: 25%;
    }
    h1 {
      text-align: center;
      font-size: 26px;
      color: $orange;
      margin: 0;
      margin-bottom: 30px;
      font-weight: 500;
    }
    form {
      input {
        width: 100%;
        font-family: "Heebo";
        font-weight: 400;
        font-size: 16px;
        padding: 10px 20px;
        border-radius: 30px;
        border: 1px solid #909090;
        transition: all 0.3s ease;
        &:not(:last-child) {
          margin-bottom: 20px;
        }
        &:focus {
          outline: none;
          border: 1px solid $orange;
        }
      }
      .btn {
        width: 100%;
        font-family: "Heebo";
        font-weight: 400;
        font-size: 16px;
        padding: 10px 20px;
        border-radius: 30px;
        transition: all 0.3s ease;
        text-align: center;
        background-color: $orange;
        color: #fff;
        border: none;
        cursor: pointer;
        &:hover {
          background-color: $orange-hover;
        }
      }
    }
  }
}
