@import "../assets/scss/variables.scss";
@import "../assets/scss/respond.scss";
.Map {
  height: calc(100vh - 75px);
  @include respond(992) {
    height: auto;
  }
  background-image: linear-gradient(#ebc3b1, #adbcd3);
  .container {
    padding: 10px;
    height: 100%;
    @include respond(992) {
      height: auto;
    }
    > .content {
      height: 100%;
      display: grid;
      grid-template-columns: 1fr 1.666fr;
      grid-template-rows: 81px calc(100% - 91px);
      column-gap: 10px;
      row-gap: 10px;
      grid-template-areas:
        "group search"
        "data map";
      @include respond(1800) {
        grid-template-columns: 1fr 1.5fr;
      }
      @include respond(1700) {
        grid-template-columns: 1fr 1.4fr;
      }
      @include respond(1600) {
        grid-template-columns: 1fr 1fr;
      }
      @include respond(1300) {
        grid-template-columns: 1fr 1.5fr;
      }
      @include respond(992) {
        grid-template-columns: 1fr;
        grid-template-rows: none;
        height: auto;
        grid-template-areas:
          "map"
          "data";
      }
      .group-wrapper {
        grid-area: group;
        @include respond(992) {
          display: none;
        }
        .group {
          background-color: $gray;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding: 10px 20px;
          border-radius: 10px;
          @include respond(1300) {
            justify-content: space-between;
          }
          .btn {
            display: none;
            background-color: $orange;
            font-size: 18px;
            color: #fff;
            padding: 8px 50px;
            border-radius: 10px;
            cursor: pointer;
            transition: all ease 0.4s;
            i {
              margin-right: 10px;
            }
            @include respond(1300) {
              display: block;
              font-size: 16px;
              padding: 8px 30px;
            }
          }
          .input-wrapper {
            // display: flex;
            // flex-direction: column;

            width: 200px;

            .FloatingSelect{
              label{
                // background: transparent;
                border-radius: 5px;
              }

              .css-nwuocg-Menu{
                border-radius: 30px;
              }
            }

            .title {
              color: #fff;
              font-size: 18px;
              @include respond(1300) {
                font-size: 16px;
              }
            }
            input {
              &.number {
                width: 75px;
                font-size: 18px;
                color: #898989;
                text-align: center;
                border-radius: 10px;
                padding: 10px;
                border: unset;
                margin-right: 20px;
                transition: all ease 0.4s;
                border: 1px solid #fff;
                &:focus {
                  outline: none;
                  border: 1px solid $orange;
                }
              }
            }
          }
        }
      }
      .search-wrapper {
        grid-area: search;
        width: 100%;
        @include respond(992) {
          display: none;
        }
        .search {
          background-color: $gray;
          display: flex;
          align-items: center;
          padding: 19px 25px;
          border-radius: 10px;
          justify-content: space-between;
          input {
            &.style {
              font-size: 18px;
              color: #898989;
              border-radius: 10px;
              padding: 10px;
              border: unset;
              transition: all ease 0.4s;
              border: 1px solid #fff;
              width: auto;
              &:focus {
                outline: none;
                border: 1px solid $orange;
              }
              &::placeholder {
                color: #898989;
              }
              @include respond(1300) {
                width: auto;
                font-size: 16px;
              }
            }
          }
          .search-btn {
            font-size: 18px;
            background-color: $orange;
            color: #fff;
            padding: 8px 50px;
            border-radius: 10px;
            cursor: pointer;
            transition: all ease 0.4s;
            &:hover {
              background-color: $orange-hover;
            }
            @include respond(1300) {
              font-size: 16px;
              padding: 8px 30px;
            }
          }
        }
      }
      .data-filters-wrapper {
        grid-area: data;
        display: flex;
        .filters-wrapper {
          width: 35%;
          padding-right: 10px;
          @include respond(1300) {
            position: fixed;
            z-index: 9999;
            left: -100%;
            top: 75px;
            width: 100%;
            height: calc(100% - 75px);
            background-color: rgba($color: #000, $alpha: 0.8);
            padding: 10px;
            display: block;
            transition: all ease-in 1ms;
            &.open {
              left: 0;
            }
          }
          .group-search {
            margin-bottom: 10px;
            position: relative;
            left: -100%;
            display: none;
            transition: all ease-in 300ms;
            .search {
              padding: 10px;
            }
            &.open {
              left: 0;
            }
            @include respond(992) {
              display: block;
            }
            > .search-wrapper {
              display: block;
              .search {
                @include respond(768) {
                  display: block;
                  text-align: center;
                }
              }
              input.style {
                @include respond(768) {
                  width: 80%;
                  margin: auto;
                  margin-bottom: 10px;
                }
                @include respond(500) {
                  width: 100%;
                }
              }
              .search-btn {
                @include respond(768) {
                  width: 80%;
                  margin: auto;
                }
                @include respond(500) {
                  width: 100%;
                }
              }
              .input-wrapper {
                display: flex;
                flex-direction: column;
                .title {
                  color: #fff;
                  font-size: 18px;
                  @include respond(1300) {
                    font-size: 16px;
                  }
                }
                input {
                  &.number {
                    width: 75px;
                    font-size: 18px;
                    color: #898989;
                    text-align: center;
                    border-radius: 10px;
                    padding: 10px;
                    border: unset;
                    margin-right: 20px;
                    transition: all ease 0.4s;
                    border: 1px solid #fff;
                    &:focus {
                      outline: none;
                      border: 1px solid $orange;
                    }
                  }
                }
              }
            }
          }
          .filter {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            overflow: hidden;
            background-color: #fff;
            height: 100%;
            overflow-y: scroll;
            position: relative;
            @include respond(1300) {
              width: 30%;
              position: absolute;
              left: -30%;
              height: calc(100% - 20px);
              transition: all ease-in 300ms;
              &.open {
                left: 0;
              }
            }

            @include respond(992) {
              width: 100%;
              left: -100%;
              height: calc(100% - 90px);
              position: relative;
            }
            @include respond(768) {
              height: calc(100% - 220px);
            }
            &::-webkit-scrollbar {
              width: 5px;
            }
            &::-webkit-scrollbar-track {
              background-color: #fff;
              background-color: rgba($color: $gray, $alpha: 0.1);
            }
            &::-webkit-scrollbar-thumb {
              background-color: $orange;
            }
            &::-webkit-scrollbar-thumb:hover {
              background-color: $orange-hover;
            }
            .title {
              padding: 5px 10px;
              font-size: 16px;
              background-color: $gray;
              color: #fff;
            }
            .btn {
              display: none;
              position: sticky;
              bottom: 0;
              background-color: $orange;
              width: 100%;
              font-size: 18px;
              color: #fff;
              padding: 8px 50px;
              cursor: pointer;
              transition: all ease 0.4s;
              text-align: center;
              @include respond(1300) {
                display: block;
              }
            }
          }
        }
        .data-wrapper {
          width: 65%;
          display: flex;
          flex-direction: column;
          background-color: #fff;

          @include respond(1300) {
            width: 100%;
          }
          .data {
            height: calc(100% - 44px);
            overflow-y: scroll;
            
            padding: 8px;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            &.loading {
              display: flex;
              justify-content: center;
              align-items: center;
            }
            &::-webkit-scrollbar {
              width: 5px;
            }
            &::-webkit-scrollbar-track {
              background-color: rgba($color: $gray, $alpha: 0.1);
            }
            &::-webkit-scrollbar-thumb {
              background-color: $orange;
            }
            &::-webkit-scrollbar-thumb:hover {
              background-color: $orange-hover;
            }
            .data-fillters {
              display: flex;
              border-radius: 10px;
              overflow: hidden;
              margin-bottom: 20px;

              .btn {
                padding: 10px 0;
                width: 100%;
                color: #fff;
                text-align: center;
                position: relative;
                cursor: pointer;
                transition: all ease-out 150ms;
                > img {
                  position: absolute;
                  left: 20px;
                  top: 50%;
                  transform: translateY(-50%);
                }
                &.by-reviews {
                  background-color: $blue;
                  &:hover {
                    background-color: $blue-hover;
                  }
                }
                &.by-az {
                  background-color: $orange;
                  &:hover {
                    background-color: $orange-hover;
                  }
                }
              }
            }
            h2 {
              color: #bdbdbd;
              font-weight: 400;
            }
          }
          .bottom-btns{
            height: 44px;
            .pagination{
              display: flex;
              column-gap: 2px;
              .btn{
                flex: 1;
                background: $turquoise;
                color: #fff;
                border: none;
                outline: none;
                padding: 10px 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                column-gap: 10px;
                font-size: max(16px, 1vw);
                cursor: pointer;
                transition: all ease-out 250ms;
    
                svg{
                  path{
                    fill: #fff;
                  }
                }
    
                &:hover{
                  background: $turquoise-hover;
    
                  svg{
                    transform: scale(1.1);
                  }
                }
    
                &:disabled {
                  background: $gray; 
                  cursor: not-allowed;
                }
              }
    
            }
          }
        }
      }
      .map-wrapper {
        grid-area: map;
        width: 100%;
        position: relative;
        @include respond(992) {
          height: 80vh;
        }
        .btn {
          display: none;
          position: absolute;
          top: 10px;
          right: 10px;
          z-index: 999;
          background-color: $orange;
          font-size: 18px;
          color: #fff;
          padding: 8px 50px;
          border-radius: 10px;
          cursor: pointer;
          transition: all ease 0.4s;
          i {
            margin-right: 10px;
          }
          @include respond(992) {
            display: block;
          }
          @include respond(500) {
            font-size: 14px;
            padding: 8px 30px;
          }
        }
        .map {
          border-radius: 10px;
          height: 100%;
          width: 100%;
          border: 5px solid #fff;
          position: relative;
          overflow: hidden;
          
          .mapboxgl-ctrl-group {
            margin-top: 5px;
            margin-left: 5px;
            width: 30px;
            left: 5px !important;
            top: 5px !important;
          }
        }
      }
    }
  }
}
