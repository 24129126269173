@import "../../assets/scss/variables.scss";
.BulletInput {
  position: relative;
  font-family: "Heebo";
  margin-bottom: 20px;
  height: 178px;
  display: flex;
  flex-direction: column;
  .bullets {
    height: 100%;
    border: 1px solid #909090;
    border-radius: 30px;
    margin-bottom: 10px;
    padding: 10px 0 10px 10px;
    overflow: hidden;
    ul {
      position: relative;
      height: 100%;
      margin: 0;
      padding: 0;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        background-color: #fff;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $orange;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: $orange-hover;
      }
      li {
        position: relative;
        padding: 5px 10px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        &::before {
          content: "";
          width: 7px;
          height: 7px;
          margin-right: 5px;
          background-color: $orange;
          border-radius: 50%;
          display: inline-block;
        }
        .delete {
          position: absolute;
          right: 10px;
          color: $red;
          cursor: pointer;
          display: none;
        }
        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
          font-family: "Heebo";
          font-weight: 400;
          font-size: 16px;
          .delete {
            display: unset;
          }
        }
      }
      .placeholder {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        font-size: 14px;
        color: #909090;
      }
    }
  }
  .input-wrapper {
    display: flex;
    input {
      width: 100%;
      font-family: "Heebo";
      font-weight: 400;
      font-size: 16px;
      padding: 10px 20px;
      border-radius: 30px;
      border: 1px solid #909090;
      transition: all ease 0.3s;
      &:focus {
        outline: none;
        border: 1px solid $orange;
      }
    }
    .btn {
      margin-left: 10px;
      text-align: center;
      width: 20%;
      border: none;
      color: #fff;
      font-family: "Heebo";
      font-weight: 400;
      font-size: 16px;
      padding: 10px 20px;
      border-radius: 30px;
      transition: all ease 0.3s;
      background-color: $orange;
      cursor: pointer;
      &:hover {
        background-color: $orange-hover;
      }
    }
  }
}
