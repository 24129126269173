@import "../../assets/scss/variables.scss";
.TariffForm {
  .container {
    .content {
      width: 80%;
      margin: auto;
      .title {
        color: #93c840;
        text-align: center;
        width: 100%;
        margin: 30px 0 20px 0;
        font-weight: 600;
      }
      p {
        font-size: 14px;
        color: #909090;
        margin: 0;
        margin-bottom: 30px;
        text-align: center;
      }
      form {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .col {
          width: 50%;
          padding: 0 10px;
        }
        .btns {
          width: 100%;
          text-align: center;
          margin-top: 50px;
          .btn {
            text-align: center;
            padding: 10px 40px;
            border-radius: 30px;
            color: #fff;
            display: inline-block;
            margin: 0px 10px;
            cursor: pointer;
            border: none;
            font-size: 16px;
            font-family: "Heebo";
            &.orange {
              background-color: #93c840;
              margin-bottom: 10px;
              &:hover {
                background-color: #678a2d;
              }
            }
            &.red {
              background-color: #ea3b3b;

              &:hover {
                background-color: #bd2d2d;
              }
            }
            &:focus {
              outline: none;
            }
            &.green {
              background-color: $green;

              &:hover {
                background-color: $green-hover;
              }
            }
          }
        }
      }
    }
  }
}
