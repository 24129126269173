@import "../../assets/scss/variables.scss";
.PdfInput {
  .more-data {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.7);
    top: 0%;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    .container {
      width: 30%;
      padding: 30px;
      border-radius: 20px;
      background-color: #fff;
      position: relative;
      .title {
        width: 100%;
        font-size: 22px;
        color: $orange;
        font-weight: 500;
        padding: 0 30px;
      }
      form {
        display: flex;
        flex-direction: column !important;
        .btn-wrapper {
          width: 100%;
          text-align: center;
          .btn {
            text-align: center;
            padding: 10px 40px;
            border-radius: 30px;
            color: #fff;
            display: inline-block;
            margin: 0px 10px;
            cursor: pointer;
            border: none;
            font-size: 16px;
            font-family: "Heebo";
            transition: background ease 0.3s;
            &.green {
              background-color: $green;
              margin-bottom: 10px;
              &:hover {
                background-color: $green-hover;
              }
            }
            &.red {
              background-color: $red;
              &:hover {
                background-color: $red-hover;
              }
            }
          }
        }
      }
    }
  }
  .preview-wrapper {
    border: 1px dashed #909090;
    height: 132px;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    border-bottom: none;
    padding: 6px;
    align-items: center;
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    &::-webkit-scrollbar {
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      background-color: #fff;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #ac5223;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #ac5223;
    }
    .preview-image {
      height: 100px;
      width: 120px;
      flex: 0 0 100px;
      margin-left: 5px;
      position: relative;
      cursor: pointer;
      &.loaded {
        &:hover {
          .options {
            display: flex;
          }
        }
      }
      > .pdf {
        text-align: center;
        height: 100%;
        width: 100%;
        object-fit: cover;
        border: 1px dashed #909090;
        border-radius: 30px;
        font-size: 14px;
        padding: 10px;
        svg {
          fill: $orange;
        }
      }
      .options {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border-radius: 30px;
        background-color: rgba($color: #000000, $alpha: 0.6);
        justify-content: center;
        align-items: center;
        img {
          padding: 5px;
          width: auto;
          height: auto;
        }
      }
    }
  }
  .input-wrapper {
    input {
      display: none;
    }
    label {
      display: block;
      font-weight: 400;
      font-size: 16px;
      padding: 10px 20px;
      border: 1px solid #93c840;
      border-bottom-right-radius: 30px;
      border-bottom-left-radius: 30px;
      transition: all ease 0.3s;
      color: #fff;
      text-align: center;
      cursor: pointer;
      background-color: #93c840;
      &:hover {
        background-color: #7da73a;
        border: 1px solid #7da73a;
      }
    }
  }
}
