@import "../assets/scss/variables.scss";
@import "../assets/scss/respond.scss";
.Login {
  .container {
    display: flex;
    min-height: calc(100vh - 75px);
    @include respond(1400) {
      min-height: calc(100vh - 75px);
    }
    @include respond(992) {
      flex-wrap: wrap;
      height: calc(100vh - 75px);
    }
    .image {
      width: 50%;
      background-size: cover;
      background-position: center;
      @include respond(992) {
        display: none;
      }
    }
    .content-wrapper {
      position: relative;
      width: 50%;
      background-image: linear-gradient(#ebc3b1, #adbcd3);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      @include respond(992) {
        width: 100%;
        height: 100%;
      }
      .content {
        width: 50%;
        margin-bottom: 50px;
        text-align: center;
        @include respond(1800) {
          width: 60%;
        }
        @include respond(1200) {
          width: 80%;
        }
        @include respond(992) {
          width: 50%;
        }
        @include respond(750) {
          width: 60%;
        }
        @include respond(600) {
          width: 70%;
        }
        @include respond(500) {
          width: 80%;
        }
        @include respond(450) {
          width: 90%;
        }
        .title {
          color: #fff;
          font-size: 50px;
          margin: 0;
          @include respond(1500) {
            font-size: 40px;
          }
        }
        .sub-title {
          font-weight: 300;
          color: #fff;
          font-size: 22px;
          margin: 0;
          margin-bottom: 50px;
          @include respond(1500) {
            font-size: 20px;
          }
        }
        form {
          margin-bottom: 10px;
          .btn {
            display: block;
            width: 100%;
            text-align: center;
            padding: 10px;
            border-radius: 30px;
            color: #fff;
            font-size: 16px;
            font-weight: 300;
            text-decoration: none;
            transition: all ease 0.3s;
            cursor: pointer;
            border: unset;
            &:not(:last-of-type) {
              margin-bottom: 20px;
            }
            &.blue {
              background-color: $blue;
              &:hover {
                background-color: $blue-hover;
              }
            }
            &.orange {
              background-color: $orange;
              &:hover {
                background-color: $orange-hover;
              }
            }
            &.gray {
              background-color: $gray;
              &:hover {
                background-color: $gray-hover;
              }
            }
            &:focus {
              outline: none;
            }
          }
        }
        span {
          .link {
            color: black;
            opacity: 0.7;
            transition: all 0.2s ease;
            &:hover {
              opacity: 1;
            }
          }
        }
        .btn {
          display: block;
          width: 100%;
          text-align: center;
          padding: 10px;
          border-radius: 30px;
          color: #fff;
          font-size: 16px;
          font-family: "Heebo";
          font-weight: 400;
          text-decoration: none;
          transition: all ease 0.3s;
          cursor: pointer;
          border: unset;
          &:not(:last-of-type) {
            margin-bottom: 20px;
          }
          &.blue {
            background-color: $blue;
            &:hover {
              background-color: $blue-hover;
            }
          }
          &.orange {
            background-color: $orange;
            &:hover {
              background-color: $orange-hover;
            }
          }
          &.gray {
            background-color: $gray;
            &:hover {
              background-color: $gray-hover;
            }
          }
          &:focus {
            outline: none;
          }
        }
      }
      p {
        position: absolute;
        color: #fff;
        font-size: 18px;
        font-family: "Heebo";
        font-weight: 400;
        text-align: center;
        bottom: 20px;
      }
    }
    .to-news {
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      h3 {
        font-size: 32px;
        color: #fff;
        margin: 10px;
      }
    }
  }
}
