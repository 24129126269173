@import "../assets/scss/variables.scss";
.SiteMngt {
  > .container {
    > .content {
      display: flex;
      width: 100%;
      height: calc(100vh - 75px);
      .data {
        width: 30%;
        padding-top: 75px;
        box-shadow: 5px 0px 5px 0px rgba(0, 0, 0, 0.05);
        display: flex;
        flex-direction: column;
        .breadcrumbs {
          width: 100%;
          padding: 0 30px;
          ul {
            padding: 0;
            margin: 0;
            list-style-type: none;
            li {
              display: inline-block;
              color: $orange;
              cursor: pointer;
              &:not(:last-child) {
                &::after {
                  content: ">";
                  margin: 0 5px;
                }
              }
              &.active {
                font-weight: 600;
              }
            }
          }
        }
        .search-wrapper {
          width: 100%;
          padding: 15px 30px;
          input {
            width: 100%;
            font-weight: 400;
            font-size: 16px;
            padding: 10px 20px;
            border-radius: 30px;
            border: 1px solid #909090;
            transition: all ease 0.3s;
            &:focus {
              outline: none;
              border: 1px solid $orange;
            }
          }
        }
        .data-title {
          color: #93c840;
          text-align: center;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          margin: 0;
          padding-bottom: 10px;
          font-size: 22px;
          font-weight: 500;
        }
        .data-list {
          overflow: auto;
          height: 100%;
          &.loading {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          &::-webkit-scrollbar {
            width: 5px;
          }
          &::-webkit-scrollbar-track {
            background-color: #fff;
          }
          &::-webkit-scrollbar-thumb {
            background-color: $orange;
            border-radius: 10px;
          }
          &::-webkit-scrollbar-thumb:hover {
            background-color: $orange-hover;
          }
          .no-results {
            color: $red;
            font-family: "Heebo";
            font-weight: 400;
            font-size: 22px;
            text-align: center;
          }
        }
        // .add-new {
        //   display: flex;
        //   align-items: center;
        //   justify-content: center;
        //   background-color: $orange;
        //   color: #fff;
        //   cursor: pointer;
        //   &:hover {
        //     background-color: $orange-hover;
        //   }
        //   img {
        //     margin-left: 30px;
        //   }
        //   .text {
        //     font-weight: 500;
        //   }
        // }
        .add-new {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $orange;
          color: #fff;
          cursor: pointer;
          &:hover {
            background-color: $orange-hover;
          }
          img {
            margin-right: 30px;
          }
          .text {
            font-weight: 500;
          }
        }
      }
      .form {
        position: relative;
        width: 70%;

        .action-btns-container{
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 30px;

          button{
              background: $orange;
              color: #fff;
              font-size: 20px;
              font-weight: 700;
              border: none;
              border-radius: 10px;
              padding: 10px 20px;
              cursor: pointer;
              transition: all ease-out 150ms;

              &:hover{
                background: $orange-hover;
              }

              &:last-of-type{
                background: $blue;
                //cursor: not-allowed;

                &:hover{
                  background: $blue-hover;
                }
              }
          }
        }
      }
      .instruction {
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
        color: $orange;
        font-family: "Heebo";
        font-weight: 400;
        font-size: 22px;
      }

      //new katy
      .bottom-btns{
        .pagination{
          display: flex;
          column-gap: 2px;
          .btn{
            flex: 1;
            background: $turquoise;
            color: #fff;
            border: none;
            outline: none;
            padding: 10px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 10px;
            font-size: max(16px, 1vw);
            cursor: pointer;
            transition: all ease-out 250ms;

            svg{
              path{
                fill: #fff;
              }
            }

            &:hover{
              background: $turquoise-hover;

              svg{
                transform: scale(1.1);
              }
            }

            &:disabled {
              background: $gray; 
              cursor: not-allowed;
            }
          }

        }
      }
    }
  }
}
