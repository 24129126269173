@import "../assets/scss/variables.scss";
.SnackBars {
  &.success,
  &.error {
    > div {
      box-shadow: none;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
      .MuiSnackbarContent-message {
        margin: auto;
        font-family: "Heebo";
        font-size: 18px;
      }
    }
  }
  &.success {
    > div {
      background-color: $green;
    }
  }
  &.error {
    > div {
      background-color: $red;
    }
  }
}
