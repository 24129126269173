@import "../assets/scss/variables.scss";
@import "../assets/scss/respond.scss";
.Header {
  position: relative;
  height: 75px;
  z-index: 9999;
  .content {
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 75px;
    display: flex;
    align-items: center;
    padding: 0px 30px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    @include respond(992) {
      padding: 0px 20px;
    }
    .logo {
      margin-top: 80px;
      cursor: pointer;
      width: 150px;
      border-radius: 50%;
      @include respond(1800) {
        margin-top: 60px;
        width: 120px;
      }
      @include respond(1300) {
        margin: 0;
        height: 100%;
        width: unset;
      }
      img {
        width: 100%;
        border-radius: 50%;
        @include respond(1300) {
          height: 100%;
          width: unset;
        }
      }
    }
    .title {
      margin-left: 50px;
      @include respond(992) {
        margin-left: 10px;
      }
      h2 {
        color: $blue;
        margin: 0;
        font-weight: 700;
        font-size: 32px;
        margin-right: 60px;
        font-weight: 700;
        @include respond(992) {
          font-size: 20px;
          margin-right: 0;
        }
      }
    }
    .user {
      display: flex;
      align-items: center;
      margin-left: auto;
      position: relative;
      .profile-image {
        border: 1px solid $blue;
        border-radius: 50%;
        @include respond(768) {
          display: none;
        }
      }
      .user-name {
        margin: 0 20px;
        color: $blue;
        @include respond(992) {
          margin: 0 10px;
        }
        @include respond(768) {
          display: none;
        }
      }
      .drop-down-btn {
        padding: 15px 20px;
        border-left: 2px solid #000000;
        border-right: 2px solid #000000;
        cursor: pointer;
        background-image: url("../assets/images/black-arrow-down.png");
        background-repeat: no-repeat;
        background-position: center;
      }
      .drop-down {
        display: none;
        position: absolute;
        top: 55px;
        background-color: #ffffff;
        width: 100%;
        border-radius: 10px;
        padding: 5px;
        margin: 0;
        list-style: none;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
        @include respond(768) {
          width: 250px;
          right: 0;
        }
        .user {
          display: none;
          @include respond(768) {
            display: flex;
          }
          .profile-image {
            display: block !important;
          }
          .user-name {
            display: block !important;
          }
        }
        li {
          font-size: 18px;
          padding: 10px 10px;
          border-radius: 10px;
          cursor: pointer;
          transition: all ease 0.3;
          &:hover {
            background-color: rgba(0, 0, 0, 0.05);
          }
        }
      }
    }
  }
}
