@import "./assets/scss/fonts.scss";
@import "./assets/scss/variables.scss";
html {
  scroll-behavior: smooth;
  * {
    box-sizing: border-box;
  }
  body {
    margin: 0;
    padding: 0;
    font-family: "Heebo", sans-serif;

    .App{
      min-height: 100vh;
    }
  }
}
