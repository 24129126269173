@import "../assets/scss/variables.scss";
@import "../assets/scss/respond.scss";

.MyOrders {
  position: relative;
  background-image: linear-gradient(#ebc3b1, #adbcd3);
  height: calc(100vh - 75px);

  @include respond(1024) {
    height: auto;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-track {
    background-color: #fff;
    background-color: rgba($color: $gray, $alpha: 0.0);
  }
  ::-webkit-scrollbar-thumb {
    background-color: $orange;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: $orange-hover;
  }

  .abs-loader-div{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn {
    position: relative;
    color: #fff;
    padding: 10px;
    border-radius: 10px;
    width: 130px;
    text-align: center;
    cursor: pointer;
    transition: all ease 0.4s;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;

    &.btn-blue {
      background-color: $blue;
      &:hover {
        background-color: $blue-hover;
      }
    }
    &.btn-yellow {
      background-color: $yello;
      margin: 0 4px;
      &:hover {
        background-color: $yello-hover;
      }
    }
    &.btn-orange {
      background-color: $orange;
      &:hover {
        background-color: $orange-hover;
      }
    }
    > img {
      position: absolute;
      left: 30px;
    }
  }

  // === Report popup styling === //
  .report-popup {
    position: fixed;
    width: 100%;
    height: calc(100vh - 75px);
    background-color: rgba($color: #000000, $alpha: 0.8);
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;

    @include respond(992) {
      padding: 15px;
    }
    .popup-container {
      background-color: #fff;
      border-radius: 10px;
      padding: 50px;
      position: relative;
      @include respond(922) {
        padding: 30px 5px;
        width: 100%;
      }
      .close {
        position: absolute;
        background-color: #ea3b3b;
        top: 0;
        right: 0;
        height: 30px;
        width: 30px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        cursor: pointer;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      form {
        width: 400px;
        @include respond(768) {
          width: 100%;
        }
        .title {
          color: $orange;
          margin: 0;
          margin-bottom: 20px;
          text-align: center;
        }
        textarea {
          width: 100%;
          height: 150px;
          font-size: 16px;
          border: 1px solid #909090;
          resize: none;
          border-radius: 10px;
          transition: all ease 0.4s;
          padding: 10px;
          font-family: "Heebo", sans-serif;
          &:focus {
            border: 1px solid $orange;
            outline: none;
          }
        }
        .btns {
          display: flex;
          justify-content: center;
          .btn {
            width: 100%;
            text-align: center;
            padding: 5px;
            border-radius: 10px;
            color: #fff;
            cursor: pointer;
            transition: all ease 0.4s;
            &.orange {
              background-color: $orange;
              margin-right: 10px;
              &:hover {
                background-color: $orange-hover;
              }
            }
            &.red {
              background-color: $red;
              &:hover {
                background-color: $red-hover;
              }
            }
          }
        }
      }
    }
  }

  .container {
    max-width: min(1500px, 95%);
    margin-inline: auto;
    padding: 75px 0;

    @include respond(1300) {
      padding: 40px 0;
    }

    .top-bar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      background-color: $gray;
      border-radius: 10px;
      padding: 10px;

      @include respond(992) {
        align-items: flex-start;
      }

      @include respond(575) {
        flex-direction: column;
        align-items: flex-end;
        row-gap: 10px;
      }

      .btns-wrapper{
        // max-width: 394px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .search-filters {
        display: flex;
        align-items: center;
        column-gap: 5px;

        @include respond(992) {
          flex-direction: column;
          row-gap: 10px;
          align-items: flex-end;
        }

        .FloatingDateInput {
          margin: 0;
        }
        .btn {
          position: relative;
          color: #fff;
          padding: 10px;
          border-radius: 10px;
          width: 250px;
          text-align: center;
          cursor: pointer;
          transition: all ease 0.4s;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 20px;
          }

          &.btn-blue {
            margin-left: auto;
            background-color: $blue;
            &:hover {
              background-color: $blue-hover;
            }
          }
          &.btn-orange {
            background-color: $orange;
            margin: 0 4px;

            @include respond(992) {
              margin: 0;
            }
            &:hover {
              background-color: $orange-hover;
            }
          }
          &.btn-gray {
            background-color: $gray;
            width: 100%;
            cursor: unset;
            @include respond(768) {
              display: none;
            }
          }
          > img {
            position: absolute;
            left: 30px;
          }
        }
      }
    }

    .main-area {
      display: flex;
      justify-content: space-between;
      width: 100%;

      @include respond(1024) {
        flex-direction: column;
        row-gap: 10px;
      }

      .orders-list {
        width: 27%;
        height: 65vh;
        background: #f5f5f5;
        border-radius: 10px;
        overflow: hidden;

        @include respond(1400) {
          width: 36%;
        }

        @include respond(1024) {
          width: 100%;
          height: 28vh;
        }

        .orders-wrapper{
          height: 100%;
          overflow-y: auto;
          padding: 10px;
          display: flex;
          flex-direction: column;
          row-gap: 20px;

          .order-item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            column-gap: 10%;
            border-radius: 18px;
            padding: max(10px, 0.833vw);
            box-shadow: 0px 0px 19px -1px rgba(0,0,0,0.27);
            -webkit-box-shadow: 0px 0px 19px -1px rgba(0,0,0,0.27);
            -moz-box-shadow: 0px 0px 19px -1px rgba(0,0,0,0.27);
            cursor: pointer;
            transition: all ease-out 200ms;

            .order-start-content{
              display: flex;
              align-items: center;
              column-gap: 10px;

              .icon{
                background: yellow;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 8px;
                border-radius: 50%;

                @include respond(575) {
                  padding: 5px;
                }

                svg{
                  width: 30px;
                  height: auto;
                  padding: 0;
                  border-radius: 0;

                  @include respond(575) {
                    width: 24px;
                  }
                }
              }
            }

            .order-end-content{
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              text-align: end;
            }

            &:hover{
              background: #005bab13;
            }
          }
        }
      }
      .orders-details {
        position: relative;
        width: 72%;
        height: 65vh;
        border-radius: 10px;
        background: #f5f5f5; 
        display: flex;
        flex-direction: column;
        // justify-content: flex-end;
        @include respond(1400) {
          width: 62%;
        }

        @include respond(1024) {
          width: 100%;
        }

        .placeholder-img{
          width: 100%;
          padding-bottom: 20px;
          margin-top: auto;
        }

        .abs.print-order{
          position:absolute;
          bottom: calc(max(1.563vw, 16px) + 1em);
          right: max(1.563vw, 16px);
          width: fit-content;
          font-weight: 700;
          font-size: 18px;
          cursor: pointer;

          @include respond(575) {
            position: static;
            margin-inline-start: auto;
            margin-inline-end: 16px;
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}
