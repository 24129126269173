@import "../assets/scss/variables.scss";
@import "../assets/scss/respond.scss";
.SitePage {
  position: relative;
  background-image: linear-gradient(#ebc3b1, #adbcd3);
  .comments-popup,
  .report-popup,
  .map-popup {
    position: fixed;
    width: 100%;
    height: calc(100vh - 75px);
    background-color: rgba($color: #000000, $alpha: 0.8);
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    @include respond(992) {
      padding: 15px;
    }
    .popup-container {
      background-color: #fff;
      border-radius: 10px;
      padding: 50px;
      position: relative;
      @include respond(922) {
        padding: 30px 5px;
        width: 100%;
      }
      .close {
        position: absolute;
        background-color: #ea3b3b;
        top: 0;
        right: 0;
        height: 30px;
        width: 30px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        cursor: pointer;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      form {
        width: 400px;
        @include respond(768) {
          width: 100%;
        }
        .title {
          color: $orange;
          margin: 0;
          margin-bottom: 20px;
          text-align: center;
        }
        textarea {
          width: 100%;
          height: 150px;
          font-size: 16px;
          border: 1px solid #909090;
          resize: none;
          border-radius: 10px;
          transition: all ease 0.4s;
          padding: 10px;
          font-family: "Heebo", sans-serif;
          &:focus {
            border: 1px solid $orange;
            outline: none;
          }
        }
        .btns {
          display: flex;
          justify-content: center;
          .btn {
            width: 100%;
            text-align: center;
            padding: 5px;
            border-radius: 10px;
            color: #fff;
            cursor: pointer;
            transition: all ease 0.4s;
            &.orange {
              background-color: $orange;
              margin-right: 10px;
              &:hover {
                background-color: $orange-hover;
              }
            }
            &.red {
              background-color: $red;
              &:hover {
                background-color: $red-hover;
              }
            }
          }
        }
      }
    }
  }
  .map-popup {
    .popup-container {
      width: 60%;
      height: calc(100% - 91px);
      @include respond(992) {
        width: 95%;
      }
      .map {
        border-radius: 10px;
        height: 100%;
        border: 5px solid #fff;
        position: relative;
        overflow: hidden;
        .mapboxgl-ctrl-group {
          margin-top: 5px;
          margin-left: 5px;
          width: 30px;
          left: 5px !important;
          top: 5px !important;
        }
      }
    }
  }
  .container {
    max-width: 1500px;
    margin: auto;
    padding: 75px 0;
    @include respond(1300) {
      padding: 30px 0;
    }
    .top-bar {
      display: flex;
      margin-bottom: 10px;
      .btn {
        position: relative;
        color: #fff;
        padding: 10px;
        border-radius: 10px;
        width: 260px;
        text-align: center;
        cursor: pointer;
        transition: all ease 0.4s;
        display: flex;
        justify-content: center;
        align-items: center;
        @include respond(992) {
          width: 300px;
        }
        @include respond(768) {
          width: 50%;
        }
        &.btn-blue {
          background-color: $blue;
          &:hover {
            background-color: $blue-hover;
          }
        }
        &.btn-orange {
          background-color: $orange;
          margin: 0 4px;
          &:hover {
            background-color: $orange-hover;
          }
        }
        &.btn-gray {
          background-color: $gray;
          width: 100%;
          cursor: unset;
          @include respond(768) {
            display: none;
          }
        }
        > img {
          position: absolute;
          left: 30px;
        }
      }
    }
    .site-content {
      background-color: #fff;
      border-radius: 10px;
      display: flex;
      overflow: hidden;
      margin-bottom: 10px;
      @include respond(992) {
        flex-wrap: wrap;
      }
      .details {
        display: flex;
        padding: 15px;
        width: 60%;
        @include respond(992) {
          width: 100%;
          flex-wrap: wrap;
        }
        .images {
          width: 180px;
          height: 160px;
          position: relative;
          cursor: pointer;
          @include respond(850) {
            width: 30%;
          }
          @include respond(768) {
            width: 100%;
            margin-bottom: 10px;
          }
          .gallery-icon {
            position: absolute;
            top: 10px;
            left: 10px;
            width: 40px;
            height: 40px;
            background-color: $blue;
            border-radius: 10px;
            padding: 5px;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
          }
        }
        .name-description {
          margin: 0 10px;
          @include respond(850) {
            width: 70%;
            margin: 0;
            padding-left: 20px;
          }
          @include respond(768) {
            width: 100%;
            margin-bottom: 10px;
            padding: 0;
            text-align: center;
          }
          .title {
            margin: 0;
            font-size: 24px;
            text-transform: capitalize;
            color: $gray;
          }
          .text {
            margin: 0;
            font-size: 16px;

            color: $gray;
          }
        }
        .social {
          margin-left: auto;
          @include respond(850) {
            width: 100%;
            text-align: center;
            border-top: 1px solid #fafafa;
            padding-top: 20px;
            margin-top: 20px;
          }
          .rating {
            span {
              font-size: 1.2rem;
            }
          }
          .comments {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              margin-left: 5px;
            }
          }
        }
      }
      .info {
        background-color: #fafafa;
        padding: 15px;
        color: $gray;
        width: 20%;
        @include respond(992) {
          width: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        @include respond(768) {
          width: 100%;
        }
        .info-item {
          display: flex;
          align-items: center;
          margin-bottom: 5px;
          @include respond(768) {
            justify-content: center;
          }
          img {
            margin-right: 5px;
          }
          a {
            color: $gray;
            text-decoration: none;
            &:hover {
              color: $orange;
            }
          }
        }
      }
      .contact {
        background-color: #fafafa;
        padding: 15px;
        color: $gray;
        width: 20%;
        @include respond(992) {
          width: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        @include respond(768) {
          width: 100%;
        }
        .person {
          .info-item {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            @include respond(798) {
              justify-content: center;
            }
            img {
              margin-right: 5px;
            }
            a {
              color: $gray;
              text-decoration: none;
              &:hover {
                color: $orange;
              }
            }
          }
          .hr {
            background-color: #e9e9e9;
          }
        }
      }
    }
    .site-bar {
      border-radius: 10px;
      display: flex;
      background-color: $gray;
      justify-content: space-between;
      padding: 10px 20px;
      @include respond(768) {
        flex-wrap: wrap;
      }
      .validity {
        @include respond(768) {
          width: 100%;
          margin-bottom: 20px;
          text-align: center;
        }
        label {
          color: #fff;
          margin-right: 10px;
        }
        span {
          display: inline-block;
          background-color: #fff;
          color: $gray;
          padding: 5px 10px;
          font-weight: 600;
          border-radius: 10px;
        }
      }
      .btns {
        display: flex;
        @include respond(768) {
          width: 100%;
          flex-wrap: wrap;
        }
        .btn-wrapper {
          position: relative;
          margin: 0 5px;
          @include respond(768) {
            width: 100%;
            margin-bottom: 10px;
          }
          &:last-child {
            margin-right: 0;
          }
          .btn {
            width: 170px;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            cursor: pointer;
            color: #fff;
            transition: all ease 0.4s;
            @include respond(992) {
              width: 140px;
            }
            @include respond(768) {
              width: 100%;
              padding: 8px 0;
            }
            &.orange {
              background-color: $orange;
              text-decoration: none;
              &:hover {
                background-color: $orange-hover;
              }
            }
            &.green {
              background-color: $green;
              &:hover {
                background-color: $green-hover;
              }
            }
            &.yello {
              background-color: $yello;
              &:hover {
                background-color: $yello-hover;
              }
            }
            &.turquoise {
              background-color: $turquoise;
              &:hover {
                background-color: $turquoise-hover;
              }
            }
          }
          .drop-down {
            display: none;
            position: absolute;
            top: 40px;
            background-color: #ffffff;
            width: 100%;
            border-radius: 10px;
            padding: 5px;
            margin: 0;
            list-style: none;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
            z-index: 10;
            li {
              padding: 5px 10px;
              border-radius: 10px;
              cursor: pointer;
              transition: all ease 0.3;
              a {
                color: #000000;
                text-decoration: none;
                display: block;
              }
            }
            &.yello {
              li {
                &:hover {
                  background-color: rgba($color: $yello, $alpha: 0.2);
                }
              }
            }
            &.turquoise {
              li {
                &:hover {
                  background-color: rgba($color: $turquoise, $alpha: 0.2);
                }
              }
            }
          }
        }
      }
    }
    .comments {
      .container {
        padding-top: 0;
        .title {
          background-color: #fff;
          border-radius: 10px;
          text-align: center;
          font-size: 24px;
          font-weight: 600;
          padding: 10px;
          color: $gray;
          margin-bottom: 10px;
        }
        .bar {
          display: flex;
          margin-bottom: 10px;
          @include respond(500) {
            flex-wrap: wrap;
          }
          .add-comment {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            background-color: $gray;
            color: #fff;
            padding: 10px;
            text-align: center;
            border-radius: 10px;
            width: 20%;
            margin-right: 10px;
            cursor: pointer;
            transition: all ease 0.4s;
            @include respond(992) {
              width: 50%;
            }
            @include respond(500) {
              width: 100%;
              margin-right: 0;
              margin-bottom: 10px;
            }
            &:hover {
              background-color: $gray-hover;
            }
            img {
              position: absolute;
              left: 20px;
              height: 25px;
            }
          }
          .add-rating {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $gray;
            color: #fff;
            padding: 10px;
            text-align: center;
            border-radius: 10px;
            width: 80%;
            @include respond(992) {
              width: 50%;
            }
            @include respond(500) {
              width: 100%;
            }
            img {
              margin: 0 4px;
            }
          }
        }
        .comments {
          .comment {
            background-color: #fff;
            border-radius: 10px;
            margin-bottom: 10px;
            padding: 25px 75px;
            @include respond(768) {
              padding: 15px 25px;
            }
            .name {
              color: $gray;
              font-size: 18px;
              font-weight: 600;
              margin-right: 30px;
            }
            .date {
              color: $gray;
              font-size: 18px;
            }
            .content {
              font-size: 18px;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}
