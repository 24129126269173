@import "../../assets/scss/variables.scss";


.form{
    ::-webkit-scrollbar-thumb{
        background-color: #93c840;
        border-radius: 10px;
    }
    ::-webkit-scrollbar-track{
        background: #fff;
    }
    
    ::-webkit-scrollbar {
        width: 5px;
    }
    .FormWithXl{
        overflow-y: auto;
        height: 90vh;
        > .popup {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.7);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 103;
            &.provider {
              .content {
                width: 700px;
              }
            }
            .content {
              background-color: #fff;
              border-radius: 30px;
              padding: 25px;
              width: 500px;
              text-align: center;
              h2 {
                font-weight: 500;
                font-size: 24px;
                margin-bottom: 0;
              }
              p {
                margin-bottom: 20px;
              }
              input {
                width: 300px;
                font-size: 16px;
                font-weight: 400;
                padding: 13px 20px;
                border-radius: 30px;
                border: 1px solid #909090;
                transition: all ease 0.3s;
                margin-bottom: 50px;
                &:focus {
                  outline: none;
                  border: 1px solid $orange;
                }
              }
              .btn {
                text-align: center;
                padding: 10px 40px;
                border-radius: 30px;
                color: #fff;
                display: inline-block;
                margin: 0px 10px;
                cursor: pointer;
                &.green {
                  background-color: $green;
                  margin-bottom: 10px;
                  &:hover {
                    background-color: $green-hover;
                  }
                }
                &.orange {
                  background-color: $orange;
        
                  &:hover {
                    background-color: $orange-hover;
                  }
                }
                &.red {
                  background-color: $red;
        
                  &:hover {
                    background-color: $red-hover;
                  }
                }
              }
            }
          }
        .step-5{
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-wrap: wrap;
            gap: 50px;
            padding: 30px;
    
            .col{
              width: 40%;
            }
            .col-4{
              width: 10%;
            }
            .col-5{
              width: 35%;
            }
            .btns{
                width: 100%;
                display: flex;
                justify-content: center;
                column-gap: 30px;
    
                .btn {
                    text-align: center;
                    padding: 10px 40px;
                    border-radius: 30px;
                    color: #fff;
                    display: inline-block;
                    margin: 0px 10px;
                    cursor: pointer;
                    &.green {
                      background-color: $green;
                      &:hover {
                        background-color: $green-hover;
                      }
                    }
                    &.orange {
                      background-color: $orange;
            
                      &:hover {
                        background-color: $orange-hover;
                      }
                    }
                    &.red {
                      background-color: $red;
            
                      &:hover {
                        background-color: $red-hover;
                      }
                    }
                }
            }
    
            .map{
                width: 55%;
                height: 450px;
                overflow: hidden;
                position: relative;
                border-radius: 30px;
    
                .loader-wrapper {
                    position: absolute;
                    background-color: rgba($color: #fff, $alpha: 0.8);
                    border-radius: 30px;
                    z-index: 101;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                > div {
                    height: 100% !important;
                }
    
                .mapboxgl-map {
                    border-radius: 30px;
                }
    
                .mapboxgl-ctrl-group {
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    z-index: 100;
                }
            }
    
            .btn-addresshearch {
                text-align: center;
                padding: 10px 40px;
                border-radius: 30px;
                color: #fff;
                cursor: pointer;
                border: none;
                font-size: 16px;
                font-family: "Heebo";
                background-color: #93c840;
                &:hover {
                  background-color: #7aa339;
                }
            }
    
            .location-popup {
                text-align: center;
                margin-top: 30px;
                background-color: $green;
                color: #fff;
                padding: 15px 40px;
                border-radius: 10px;
                box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
            }
            
            .xl-files{
                display: flex;
                justify-content: space-around;
                column-gap: 50px;
                width: 100%;
                padding-bottom: 50px;

                .download, .upload{
                    width: 43%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    flex-direction: column;
                    row-gap: 10px;
                    border-radius: 30px;
                    padding: 30px;
                    text-decoration: none;
                    text-align: center;
                    transition: all ease-out 200ms;
                    cursor: pointer;
                    background: $turquoise;
                    color: #fff;

                    svg{
                        fill: #fff;
                        transition: all ease-out 200ms;
                    }

                    &:hover{
                        background: $turquoise-light;
                        color: #000;
                        svg{
                            fill: #000;
                        }
                    }
                }

                .upload{
                    background: $blue;

                    label{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        row-gap: 10px;
                        cursor: pointer;

                        input{
                            display: none;
                        }

                        .filename p{
                            display: flex;
                            align-items: center;
                            column-gap: 10px;
                            margin: 0;

                            svg{
                                fill: #00e508;
                                -webkit-box-shadow: 0px 0px 15px 0px rgb(50, 50, 50); 
                                box-shadow: 0px 0px 15px 0px rgb(50, 50, 50);
                                border-radius: 50%;
                                background: rgb(50, 50, 50);
                            }
                        }
                    }

                    &:hover{
                        background: $blue-light;
                        color: #000;
                        svg{
                            fill: #000;
                        }
                    }
                }
            }
        }
    }
}