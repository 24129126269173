@import "../assets/scss/variables.scss";
.Loader {
  text-align: center;
  .circle {
    display: inline-block;
    height: 41px;
    width: 41px;
    border: 4px rgba(0, 0, 0, 0.25) solid;
    border-top: 7px $blue solid;
    border-right: 7px $blue solid;
    border-bottom: 7px $blue solid;
    border-left: 7px $orange solid;
    border-radius: 50%;
    animation: spin 1s infinite linear;
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
