@import "../../assets/scss/variables.scss";
.FloatingTextarea {
  position: relative;
  font-family: "Heebo";
  margin-bottom: 20px;
  height: 178px;
  label {
    position: absolute;
    top: 25px;
    left: 15px;
    transform: translateY(-50%);
    color: #909090;
    padding: 0 5px;
    transition: all ease 0.3s;
    line-height: 1;
    cursor: context-menu;
    background-color: #fff;
    &.float {
      top: 0;
    }
    &.blue {
      color: $blue;
    }
    &.orange {
      color: $orange;
    }
  }
  textarea {
    width: 100%;
    font-family: "Heebo";
    font-weight: 400;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 30px;
    border: 1px solid #909090;
    transition: all ease 0.3s;
    height: 178px;
    resize: none;
    &:focus {
      outline: none;
      &.blue {
        border: 1px solid $blue;
      }
      &.orange {
        border: 1px solid $orange;
      }
    }
  }
  .tooltip {
    position: absolute;
    padding: 5px 10px;
    color: #fff;
    background-color: $red;
    border-radius: 5px;
    font-size: 14px;
    right: 0;
    top: 50%;
    transform: translate(calc(100% + 15px), -50%);
    z-index: 100;
    &::after {
      position: absolute;
      content: "";
      width: 8px;
      height: 8px;
      border-radius: 2px;
      background-color: $red;
      left: 0px;
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &.left {
      right: unset;
      left: 0;
      transform: translate(calc(-100% - 15px), -50%);
      &::after {
        left: unset;
        right: 0;
        transform: translate(50%, -50%) rotate(45deg);
      }
    }
  }
}
