@import "../assets/scss/variables.scss";
@import "../assets/scss/respond.scss";
.mapboxgl-popup {
  top: 50% !important;
  width: 100% !important;
  height: 100%;
  transform: translate(0%, -50%) !important;
  z-index: 100 !important;
  .mapboxgl-popup-content {
    padding: 30px;
    border-radius: 10px !important;
    width: 80%;
    margin: auto;
    @include respond(1300) {
      width: 95%;
    }
    .mapboxgl-popup-close-button {
      border-radius: 5px;
      background-color: $red;
      width: 20px;
      height: 20px;
      top: 5px;
      right: 5px;
      color: #fff;
      font-size: 20px;
      line-height: 0;
      transition: all ease 0.4s;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: $red-hover;
      }
      &:focus {
        outline: none;
      }
    }
    .content {
      .site-data {
        display: flex;
        width: 100%;
        @include respond(500) {
          flex-wrap: wrap;
        }
        .image {
          width: 20%;
          img {
            width: 100%;
            height: 100px;
            object-fit: cover;
            border-radius: 10px;
          }
        }
        .discription {
          padding: 0 10px;
          width: 60%;
          @include respond(500) {
            width: 80%;
          }
          .title {
            margin: 0;
            font-size: 20px;
            text-transform: capitalize;
            color: $gray;
            @include respond(500) {
              font-size: 16px;
            }
          }
          .text {
            margin: 0;
            font-size: 16px;

            color: $gray;
            margin-bottom: 10px;
            @include respond(500) {
              font-size: 14px;
            }
          }
        }
        .social {
          width: 20%;
          display: flex;
          align-items: center;
          flex-direction: column;
          @include respond(500) {
            width: 100%;
            flex-direction: row;
            justify-content: space-around;
          }
          .rating {
            span {
              font-size: 1.2rem;
            }
          }
          .comments {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              margin-left: 5px;
            }
          }
        }
      }
      .activity-data {
        width: 100%;
        margin-bottom: 5px;
        .activity {
          width: 100%;
          border-radius: 5px;
          padding: 5px 10px;
          background-color: #efefef;
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          @include respond(1650) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
          .name {
            text-transform: capitalize;
            @include respond(1650) {
              margin-bottom: 10px;
            }
          }
          .more-data {
            display: flex;
            @include respond(500) {
              flex-direction: column;
            }
            .data {
              margin: 0 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              @include respond(500) {
                margin: 5px 10px;
                font-size: 14px;
              }
              img {
                margin-right: 5px;
              }
            }
          }
        }
      }
      a {
        width: 100%;
        display: block;
        border-radius: 5px;
        padding: 5px 10px;
        background-color: $orange;
        font-weight: 600;
        color: #fff;
        text-decoration: none;
        text-align: center;
        transition: all ease 0.4s;
        &:hover {
          background-color: $orange-hover;
        }
      }
    }
  }
}
