@import "../../assets/scss/variables.scss";
.ActivityForm {
  .container {
    .content {
      width: 80%;
      margin: auto;
      .title {
        color: #93c840;
        text-align: center;
        width: 100%;
        margin: 30px 0 20px 0;
        font-weight: 600;
      }
      .stepper {
        display: flex;
        justify-content: space-around;
        margin-bottom: 70px;
        .step {
          position: relative;
          width: 100%;
          &:not(:first-child) {
            .num {
              &::after {
                content: "";
                position: absolute;
                width: calc(50% - 30px);
                background-color: #93c840;
                height: 1px;
                left: 0;
              }
            }
          }
          &:not(:last-child) {
            .num {
              &::before {
                content: "";
                position: absolute;
                width: calc(50% - 30px);
                background-color: #93c840;
                height: 1px;
                right: 0;
              }
            }
          }
          .num {
            margin: auto;
            border: 1px solid #93c840;
            color: #93c840;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            line-height: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all ease 0.3s;
            &.current,
            &.completed {
              background-color: #93c840;
              color: #fff;
            }
          }
          .text {
            position: absolute;
            right: 50%;
            transform: translateX(50%);
            color: #93c840;
            margin-top: 5px;
            font-size: 14px;
          }
        }
      }
      p {
        font-size: 16px;
        color: #ea3b3b;
        margin: 0;
        margin-bottom: 30px;
        text-align: center;
        font-weight: 700;
      }
      form {
        display: flex;
        flex-wrap: wrap;
        &.step-2 {
          .col {
            &:first-child {
              width: 12% !important;
            }
            width: 43% !important;
          }
        }
        .col {
          width: 50%;
          padding: 0 10px;
          > label {
            display: block;
            margin-bottom: 20px;
            font-size: 16px;
            font-weight: 400;
            color: #909090;
            width: 100%;
            padding: 11px 20px;
          }
        }

        .btns {
          width: 100%;
          text-align: center;
          margin-top: 50px;
          .btn {
            text-align: center;
            padding: 10px 40px;
            border-radius: 30px;
            color: #fff;
            display: inline-block;
            margin: 0px 10px;
            cursor: pointer;
            border: none;
            font-size: 16px;
            font-family: "Heebo";
            &.gray {
              background-color: $gray;
              margin-bottom: 10px;
              &:hover {
                background-color: $gray-hover;
              }
            }
            &.red {
              background-color: $red;

              &:hover {
                background-color: $red-hover;
              }
            }
            &:focus {
              outline: none;
            }
            &.green {
              background-color: $green;

              &:hover {
                background-color: $green-hover;
              }
            }
          }
        }
      }
    }
  }
}
