@import "../assets/scss/respond.scss";
.Footer {
  .content {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    padding: 10px 50px;
    @include respond(768) {
      flex-direction: column;
    }
    > div {
      display: flex;
      align-items: center;
      width: 100%;
      &.logo {
        justify-content: flex-start;
        @include respond(768) {
          justify-content: center;
        }
        img {
          height: 100px;
          margin-left: 50px;
          cursor: pointer;
          border-radius: 50%;
          @include respond(1400) {
            height: 50px;
            margin-left: 0px;
          }
        }
        h2 {
          color: #1a569d;
          font-size: 32px;
          font-weight: 300;
          @include respond(1400) {
            font-size: 20px;
          }
        }
      }
      &.rights {
        justify-content: center;
        h2 {
          color: #101010;
          font-size: 22px;
          font-weight: 200;
          @include respond(1400) {
            font-size: 16px;
          }
        }
      }
      &.theguy {
        justify-content: flex-end;
        @include respond(768) {
          justify-content: center;
        }
        img {
          height: 35px;
          @include respond(1400) {
            height: 30px;
          }
        }
      }
    }
  }
}
