.order-data-container{

    padding: max(1.563vw, 16px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .start-content{
        display: flex;
        align-items: center;
        justify-content: space-between;
        
    }
    
    .main-content{
        margin-top: MAX(3vw, 30px);
        .content-wrapper{
            // display: flex;
            // justify-content: space-between;
            // align-items: center;
        }
    }

    .bottom-content{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
}