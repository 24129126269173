@import "../assets/scss/variables.scss";
.Activity {
  display: flex;
  align-items: center;
  padding: 30px;
  position: relative;
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
  cursor: pointer;
  transition: all ease 0.3s;
  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.05);
  }
  .details {
    width: 100%;
    margin: 0 20px;
    .name,
    .description {
      margin: 0;
      line-height: 1;
    }
    .name {
      font-size: 22px;
      margin-bottom: 10px;
      font-weight: 500;
      text-transform: capitalize;
    }
    .description {
      font-size: 16px;
      font-weight: 400;
    }
  }
  .btns {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .btn {
      text-align: center;
      padding: 5px 10px;
      border-radius: 10px;
      color: #fff;
      background-color: $orange;
      transition: all ease 0.4s;
      &:hover {
        background-color: $orange-hover;
      }

      &.blue{
        background-color: $blue;
        &:hover {
          background-color: $blue-hover;
        }
      }
    }
  }
}
