@import "../assets/scss/variables.scss";
.Tariff {
  display: flex;
  align-items: center;
  padding: 30px;
  position: relative;
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
  cursor: pointer;
  transition: all ease 0.3s;
  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.05);
  }
  .details {
    width: 100%;
    margin: 0 20px;
    .name,
    .description {
      margin: 0;
      line-height: 1;
    }
    .name {
      font-size: 22px;
      margin-bottom: 10px;
      font-weight: 500;
    }
    .description {
      padding: 0;
      margin: 0;
      display: flex;
      list-style: none;
      li {
        position: relative;
        padding-left: 15px;
        margin-right: 15px;
        &::before {
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          position: absolute;
          content: "";
          height: 5px;
          width: 5px;
          border-radius: 50%;
          background-color: $orange;
        }
      }
    }
  }
  .price {
    color: $orange;
    font-weight: 600;
    font-size: 22px;
    span {
      margin: 0 5px;
    }
  }
}
