@import "../assets/scss/respond.scss";
.Slider {
  position: fixed;
  width: 100%;
  height: calc(100vh - 75px);
  background-color: rgba($color: #000000, $alpha: 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  .container {
    position: relative;
    border-radius: 10px;
    width: 60%;
    height: 90%;
    background-color: #fff;
    padding: 40px;
    @include respond(992) {
      width: 95%;
      padding: 30px 5px;
    }
    .close {
      position: absolute;
      background-color: #ea3b3b;
      top: 0;
      right: 0;
      height: 30px;
      width: 30px;
      border-top-right-radius: 10px;
      border-bottom-left-radius: 10px;
      cursor: pointer;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .images-wrapper {
      height: 80%;
      width: 100%;
      display: flex;
      align-items: center;
      .slider-content {
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        height: 100%;
        width: 100%;
        > img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          opacity: 0;
          transition: all ease 1s;
          &.active {
            opacity: 1;
          }
        }
      }
      .nav {
        &.nav-prev {
          margin-right: 10px;
          img {
            transform: rotate(90deg);
            cursor: pointer;
          }
        }
        &.nav-next {
          margin-left: 10px;
          img {
            transform: rotate(-90deg);
            cursor: pointer;
          }
        }
      }
    }
    .nvigation {
      height: 20%;
      padding: 0 17px;
      padding-top: 10px;
      margin-top: 10px;
      display: flex;
      @include respond(768) {
        height: 15%;
      }
      .img-wrapper {
        padding: 10px;
        width: 20%;
        img {
          border-radius: 10px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
