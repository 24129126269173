@import "../assets/scss/variables.scss";
.UsersMngt {
  position: relative;
  > .popup {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    .content {
      background-color: #fff;
      border-radius: 30px;
      padding: 25px;
      width: 500px;
      text-align: center;
      h2 {
        font-weight: 500;
        font-size: 24px;
      }
      .btn {
        text-align: center;
        padding: 10px 40px;
        border-radius: 30px;
        color: #fff;
        display: inline-block;
        margin: 0px 10px;
        cursor: pointer;
        &.blue {
          background-color: $blue;
          margin-bottom: 10px;
          &:hover {
            background-color: $blue-hover;
          }
        }
        &.red {
          background-color: $red;

          &:hover {
            background-color: $red-hover;
          }
        }
      }
    }
  }
  > .container {
    > .content {
      display: flex;
      width: 100%;
      height: calc(100vh - 75px);
      .users {
        width: 25%;
        padding-top: 75px;
        box-shadow: 5px 0px 5px 0px rgba(0, 0, 0, 0.05);
        display: flex;
        flex-direction: column;
        .search-wrapper {
          width: 100%;
          padding: 15px 30px;
          input {
            width: 100%;
            font-weight: 400;
            font-size: 16px;
            padding: 10px 20px;
            border-radius: 30px;
            border: 1px solid #909090;
            transition: all ease 0.3s;
            &:focus {
              outline: none;
              border: 1px solid $blue;
            }
          }
        }
        .users-list {
          overflow: auto;
          height: 100%;
          &.loading {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          &::-webkit-scrollbar {
            width: 5px;
          }
          &::-webkit-scrollbar-track {
            background-color: rgba($color: #000000, $alpha: 0.05);
          }
          &::-webkit-scrollbar-thumb {
            background: $blue;
            border-radius: 10px;
          }
          &::-webkit-scrollbar-thumb:hover {
            background: $blue-hover;
          }
          .user {
            display: flex;
            align-items: center;
            padding: 30px;
            position: relative;
            border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
            cursor: pointer;
            transition: all ease 0.3s;
            &:hover {
              background-color: rgba($color: #000000, $alpha: 0.05);
            }
            img {
              border-radius: 50%;
              border: 1px solid $blue;
              height: 100%;
            }
            .details {
              margin: 0 40px;
              h2,
              h3 {
                margin: 0;
                line-height: 1;
                &.name {
                  font-size: 22px;
                  margin-bottom: 10px;
                  font-weight: 500;
                }
                &.company,
                &.email {
                  font-size: 18px;
                  font-weight: 400;
                  &:not(:last-child) {
                    margin-bottom: 5px;
                  }
                }
              }
            }
            .actions {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              margin-left: auto;
              .btn {
                text-align: center;
                padding: 5px 10px;
                border-radius: 10px;
                color: #fff;
                &.blue {
                  background-color: $blue;
                  margin-bottom: 10px;
                  &:hover {
                    background-color: $blue-hover;
                  }
                }
                &.red {
                  background-color: $red;

                  &:hover {
                    background-color: $red-hover;
                  }
                }
              }
            }
          }
          .no-search-results {
            color: $red;
            font-family: "Heebo";
            font-weight: 400;
            font-size: 22px;
            text-align: center;
          }
        }
        .add-new {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $blue;
          color: #fff;
          cursor: pointer;
          transition: all ease 0.3s;
          &:hover {
            background-color: $blue-hover;
          }
          img {
            margin-right: 30px;
          }
          .text {
            font-weight: 500;
          }
        }
      }
      .form {
        position: relative;
        width: 75%;
        .instruction {
          position: absolute;
          top: 50%;
          right: 50%;
          transform: translate(50%, -50%);
          color: $blue;
          font-family: "Heebo";
          font-weight: 400;
          font-size: 22px;
          text-align: center;
        }
      }
    }
  }
}
