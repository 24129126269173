@import "../assets/scss/variables.scss";
.NotFound {
  height: calc(100vh - 195px);
  background-image: linear-gradient(#ebc3b1, #adbcd3);
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    text-align: center;
    h1 {
      text-transform: uppercase;
      font-size: 100px;
      font-weight: 700;
      margin: 0;
      color: $blue;
    }
    h2 {
      font-size: 50px;
      margin: 0;
      color: $orange;
    }
    p {
      font-size: 30px;
      text-align: center;
      margin-bottom: 100px;
    }
    a {
      display: inline-block;
      font-size: 18px;
      padding: 10px 30px;
      background-color: $orange;
      color: #fff;
      border-radius: 30px;
      text-decoration: none;
      transition: all ease 0.4s;
    }
  }
}
