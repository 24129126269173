$blue: #005aab;
$blue-hover: #003667;
$blue-light: #61a1de;
// $orange: #f26522;
// $orange-hover: #913d14;
//new-branding
$orange: #93c840;
$orange-hover: #7ba637;
//end new branding
$gray: #4b4953;
$gray-hover: #2d2c32;
$red: #ea3b3b;
$red-hover: #bd2d2d;
$green: #4caf50;
$green-hover: #2e6930;
$yello: #fec02e;
$yello-hover: #664d12;
$turquoise: #24a3b7;
$turquoise-light: #6fddee;
$turquoise-hover: #1e8fa0;


