@import "../../assets/scss/variables.scss";
.SiteForm {
  > .popup {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 103;
    &.provider {
      .content {
        width: 700px;
      }
    }
    .content {
      background-color: #fff;
      border-radius: 30px;
      padding: 25px;
      width: 500px;
      text-align: center;
      h2 {
        font-weight: 500;
        font-size: 24px;
        margin-bottom: 0;
      }
      p {
        margin-bottom: 20px;
      }
      input {
        width: 300px;
        font-size: 16px;
        font-weight: 400;
        padding: 13px 20px;
        border-radius: 30px;
        border: 1px solid #909090;
        transition: all ease 0.3s;
        margin-bottom: 50px;
        &:focus {
          outline: none;
          border: 1px solid $orange;
        }
      }
      .btn {
        text-align: center;
        padding: 10px 40px;
        border-radius: 30px;
        color: #fff;
        display: inline-block;
        margin: 0px 10px;
        cursor: pointer;
        &.green {
          background-color: $green;
          margin-bottom: 10px;
          &:hover {
            background-color: $green-hover;
          }
        }
        &.orange {
          background-color: $orange;

          &:hover {
            background-color: $orange-hover;
          }
        }
        &.red {
          background-color: $red;

          &:hover {
            background-color: $red-hover;
          }
        }
      }
    }
  }
  .container {
    .content {
      width: 80%;
      margin: auto;
      .title {
        color: #80ae36;
        text-align: center;
        width: 100%;
        margin: 30px 0 20px 0;
        font-weight: 600;
      }
      .stepper {
        display: flex;
        justify-content: space-around;
        margin-bottom: 70px;
        .step {
          position: relative;
          width: 100%;
          &:not(:first-child) {
            .num {
              &::after {
                content: "";
                position: absolute;
                width: calc(50% - 30px);
                background-color: #93c840;
                height: 1px;
                left: 0;
              }
            }
          }
          &:not(:last-child) {
            .num {
              &::before {
                content: "";
                position: absolute;
                width: calc(50% - 30px);
                background-color: #93c840;
                height: 1px;
                right: 0;
              }
            }
          }
          .num {
            margin: auto;
            border: 1px solid #93c840;
            color: #93c840;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            line-height: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all ease 0.3s;
            &.current,
            &.completed {
              background-color: #93c840;
              color: #fff;
            }
          }
          .text {
            position: absolute;
            right: 50%;
            transform: translateX(50%);
            color: #93c840;
            margin-top: 5px;
            font-size: 14px;
          }
        }
      }
      p {
        font-size: 14px;
        color: #909090;
        margin: 0;
        margin-bottom: 30px;
        text-align: center;
      }
      form {
        display: flex;
        flex-wrap: wrap;
        .col {
          width: 50%;
          padding: 0 10px;
          .map {
            flex: 1 1 auto;
            height: 500px;
            overflow: hidden;
            position: relative;
            .loader-wrapper {
              position: absolute;
              background-color: rgba($color: #fff, $alpha: 0.8);
              border-radius: 30px;
              z-index: 101;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            > div {
              height: 100% !important;
            }
            .mapboxgl-map {
              border-radius: 30px;
            }
            .mapboxgl-ctrl-group {
              position: absolute;
              top: 20px;
              right: 20px;
              z-index: 100;
            }
          }
          .location-popup {
            text-align: center;
            margin-top: 30px;
            background-color: $green;
            color: #fff;
            padding: 15px 40px;
            border-radius: 10px;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
          }
          .btn-addresshearch {
            text-align: center;
            padding: 10px 40px;
            border-radius: 30px;
            color: #fff;
            cursor: pointer;
            border: none;
            font-size: 16px;
            font-family: "Heebo";
            background-color: #93c840;
            &:hover {
              background-color: #7ca638;
            }
          }
          .sub-title {
            font-size: 22px;
            color: #93c840;
            font-weight: 500;
            padding: 0 20px;
          }
          p {
            font-size: 14px;
            color: #909090;
            margin: 0;
            margin-bottom: 30px;
          }
        }
        .btns {
          width: 100%;
          text-align: center;
          margin-top: 50px;
          .btn {
            text-align: center;
            padding: 10px 40px;
            border-radius: 30px;
            color: #fff;
            display: inline-block;
            margin: 0px 10px;
            cursor: pointer;
            border: none;
            font-size: 16px;
            &.gray {
              background-color: $gray;
              margin-bottom: 10px;
              &:hover {
                background-color: $gray-hover;
              }
            }
            &.red {
              background-color: $red;
              &:hover {
                background-color: $red-hover;
              }
            }
            &.green {
              background-color: $green;
              &:hover {
                background-color: $green-hover;
              }
            }
            &:focus {
              outline: none;
            }
          }
        }
      }
    }
  }
}
