@import "../../assets/scss/variables.scss";
.Input {
  position: relative;
  margin-bottom: 15px;
  input {
    width: 100%;
    font-weight: 300;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 30px;
    border: 1px solid #909090;
    transition: all ease 0.3s;
    &.orange {
      &:focus {
        outline: none;
        border: 1px solid $orange;
      }
    }
    &.blue {
      &:focus {
        outline: none;
        border: 1px solid $blue;
      }
    }
    &.gray {
      &:focus {
        outline: none;
        border: 1px solid $gray;
      }
    }
  }
  .tooltip {
    position: absolute;
    padding: 5px 10px;
    color: #fff;
    background-color: $red;
    border-radius: 5px;
    font-size: 14px;
    right: 0;
    top: 50%;
    transform: translate(calc(100% + 15px), -50%);
    &::after {
      position: absolute;
      content: "";
      width: 8px;
      height: 8px;
      border-radius: 2px;
      background-color: $red;
      left: 0px;
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
}
