@font-face {
  font-family: "Heebo";
  src: url("../fonts/Heebo-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: "Heebo";
  src: url("../fonts/Heebo-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Heebo";
  src: url("../fonts/Heebo-Bold.ttf");
  font-weight: 700;
}
