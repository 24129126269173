@import "../assets/scss/variables.scss";
.Site {
  display: flex;
  align-items: center;
  padding: 30px;
  position: relative;
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
  cursor: pointer;
  transition: all ease 0.3s;
  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.05);
  }
  .image {
    width: 300px;
    height: 100px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      object-fit: cover;
    }
  }
  .details {
    width: 100%;
    margin: 0 20px;
    .name,
    .description {
      margin: 0;
      line-height: 1;
    }
    .name {
      font-size: 22px;
      margin-bottom: 10px;
      font-weight: 500;
      text-transform: capitalize;
    }
    .description {
      font-size: 16px;
      font-weight: 400;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;  
      overflow: hidden;
    }
  }
  .btns {
    .btn {
      text-align: center;
      padding: 5px 10px;
      border-radius: 10px;
      color: #fff;
      background-color: $orange;
      transition: all ease 0.4s;
      &:hover {
        background-color: $orange-hover;
      }

      &.link{
        background-color: $blue;
        margin-top: 8px;
        a{
          color: #fff;
          text-decoration: none;
        }

        &:hover{
          background-color: $blue-hover;
        }
      }
    }
  }
}
