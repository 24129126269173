.ImageInput {
  .preview-popup {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.7);
    top: 0%;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    .preview-content {
      width: 60%;
      height: 70%;
      padding: 30px;
      border-radius: 20px;
      background-color: #fff;
      position: relative;
      .close {
        position: absolute;
        background-color: #ea3b3b;
        top: 0;
        right: 0;
        height: 30px;
        width: 30px;
        border-top-right-radius: 20px;
        border-bottom-left-radius: 20px;
        cursor: pointer;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
      }
    }
  }
  .preview-wrapper {
    border: 1px dashed #909090;
    height: 132px;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    border-bottom: none;
    padding: 6px;
    align-items: center;
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    &::-webkit-scrollbar {
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      background-color: #fff;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #ac5223;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #ac5223;
    }
    .preview-image {
      height: 100px;
      width: 100px;
      flex: 0 0 100px;
      margin-left: 5px;
      position: relative;
      cursor: pointer;
      &.loaded {
        &:hover {
          .options {
            display: flex;
          }
        }
      }
      > img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border: 1px dashed #909090;
        border-radius: 30px;
      }
      .options {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border-radius: 30px;
        background-color: rgba($color: #000000, $alpha: 0.6);
        justify-content: center;
        align-items: center;
        img {
          padding: 5px;
          width: auto;
          height: auto;
        }
      }
    }
  }
  .input-wrapper {
    input {
      display: none;
    }
    label {
      display: block;
      font-weight: 400;
      font-size: 16px;
      padding: 10px 20px;
      border: 1px solid #93c840;
      border-bottom-right-radius: 30px;
      border-bottom-left-radius: 30px;
      transition: all ease 0.3s;
      color: #fff;
      text-align: center;
      cursor: pointer;
      background-color: #93c840;
      &:hover {
        background-color: #78a236;
        border: 1px solid #78a236;
      }
    }
  }
}
