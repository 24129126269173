@import "../assets/scss/variables.scss";
@import "../assets/scss/respond.scss";
.FullActivity {
  margin-bottom: 10px;
  .container {
    padding: 0;
    background-color: #fff;
    margin-top: 10px;
    overflow: hidden;
    border-radius: 10px;
    .top-bar {
      display: flex;
      justify-content: space-between;
      margin: 0;
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
      @include respond(992) {
        flex-wrap: wrap;
      }
      .name {
        display: flex;
        background-color: $gray;
        color: #fff;
        padding: 10px;
        width: 50%;
        text-transform: capitalize;
        cursor: pointer;
        transition: all ease 0.4s;
        font-size: 18px;
        @include respond(992) {
          width: 100%;
        }
        @include respond(768) {
          font-size: 16px;
        }
        &:hover {
          background-color: $gray-hover;
        }
        img {
          left: 20px;
          height: 25px;
          margin-right: 20px;
        }
      }
      .details {
        padding: 10px;
        display: flex;
        @include respond(992) {
          width: 100%;
        }
        @include respond(500) {
          flex-wrap: wrap;
        }
        .data {
          margin: 0 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          @include respond(500) {
            width: 100%;
            margin: 5px 0;
          }
          img {
            margin-right: 5px;
          }
        }
      }
    }
    .information {
      transition: all ease 0.4s;
      .content {
        display: flex;
        padding: 35px 40px;
        @include respond(992) {
          flex-wrap: wrap;
        }
        .text {
          width: 100%;
          padding-right: 40px;
          @include respond(992) {
            width: 100%;
            padding: 0;
            margin-bottom: 50px;
          }
          .title {
            font-size: 16px;
            font-weight: 600;
            color: $gray;
          }
          p {
            margin-top: 5px;
            margin-bottom: 20px;
          }
          ul {
            margin-top: 5px;
            margin-bottom: 20px;
            padding-left: 20px;
          }
          .time {
            display: flex;
            .day {
              width: 100px;
            }
          }
        }
        .price-list {
          width: 100%;
          padding-left: 40px;
          @include respond(992) {
            width: 100%;
            padding: 0;
          }
          .table-wrapper {
            overflow-x: auto;
            table,
            th,
            td {
              border: 1px solid black;
              border-collapse: collapse;
              text-align: center;
            }
            table {
              width: 100%;
              td,
              th {
                width: 20%;
                padding: 10px;
              }
              td {
                .input-wrapper {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  input {
                    padding: 10px;
                    text-align: center;
                    width: 70px;
                    border-radius: 5px;
                    border: none;
                    border: 1px solid #000;
                    margin: 0 10px;
                  }
                  img {
                    filter: invert(0.4);
                    width: 15px;
                    height: 15px;
                    cursor: pointer;
                    &:hover {
                      filter: invert(0.8);
                    }
                  }
                }
              }
            }
          }
          .order-details {
            padding: 30px 0 0px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            .FloatingDateInput {
              width: 50%;
              padding-right: 5px;
            }
            .FloatingInput {
              width: 50%;
              padding-left: 5px;
            }
            .FloatingTextarea {
              width: 100%;
            }
          }
          .submit {
            color: #fff;
            padding: 10px;
            border-radius: 10px;
            text-align: center;
            cursor: pointer;
            transition: all ease 0.4s;
            background-color: $orange;
          }
        }
      }
    }
  }
}
