@import "../assets/scss/variables.scss";
.FullSite {
  background-color: #fff;
  padding: 10px;
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
  cursor: pointer;
  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.05);
  }
  .content {
    display: flex;
    .social {
      width: 30%;
      > img {
        width: 100%;
        height: 90px;
        object-fit: cover;
        border-radius: 5px;
      }
      .rating {
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 1.2rem;
        }
      }
      .comments {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          margin-left: 5px;
        }
      }
    }
    .details {
      padding-left: 10px;
      width: 70%;
      .title {
        font-size: 19px;
        font-weight: 600;
        text-transform: capitalize;
      }
      .description {
        font-size: 16px;
        font-weight: 400;
        margin: 0;
        margin-bottom: 10px;
        color: rgb(75, 75, 75);
        word-break: break-all;
      }
      .more {
        font-size: 16;
        padding-left: 10px;
        position: relative;
        &::before {
          content: "";
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          position: absolute;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: #000;
        }
      }
    }
  }
}
