@import "../../assets/scss/variables.scss";
.UserForm {
  .container {
    .content {
      width: 80%;
      margin: auto;
      .title {
        color: $blue;
        text-align: center;
        width: 100%;
        margin: 30px 0 20px 0;
        font-weight: 500;
      }
      form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .form-section {
          display: flex;
          flex-wrap: wrap;
          width: 50%;
          margin: 0 auto;
          .sub-title {
            width: 100%;
            font-size: 22px;
            color: $blue;
            font-weight: 500;
            padding: 0 30px;
          }
          .col-full {
            width: 100%;
            padding: 0 10px;
          }
          .col {
            width: 50%;
            padding: 0 10px;
          }
        }
      }
      .btn-wrapper {
        width: 100%;
        text-align: center;
        margin-top: 50px;
        .btn {
          text-align: center;
          padding: 10px 40px;
          border-radius: 30px;
          color: #fff;
          display: inline-block;
          margin: 0px 10px;
          cursor: pointer;
          border: none;
          font-size: 16px;
          font-family: "Heebo";
          transition: background ease 0.3s;
          &.blue {
            background-color: $blue;
            margin-bottom: 10px;
            &:hover {
              background-color: $blue-hover;
            }
          }
          &.red {
            background-color: $red;
            &:hover {
              background-color: $red-hover;
            }
          }
        }
      }
    }
  }
}
