@import "../../assets/scss/variables.scss";
.FloatingDateInput {
  margin-bottom: 20px;
  position: relative;
  label {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    color: #909090;
    padding: 0 5px;
    transition: all ease 0.3s;
    line-height: 1;
    cursor: context-menu;
    background-color: #fff;
    z-index: 1;
    &.float {
      top: 0;
    }
    &.blue {
      color: $blue;
    }
    &.orange {
      color: $orange;
    }
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker-popper {
    left: unset !important;
    right: 25px !important;
  }
  .react-datepicker__triangle {
    left: unset !important;
    right: 10px !important;
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: $orange !important;
    &:hover {
      background-color: $orange-hover !important;
    }
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background-color: $orange !important;
    &:hover {
      background-color: $orange-hover !important;
    }
  }
  input {
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    padding: 13px 20px;
    border-radius: 30px;
    border: 1px solid #909090;
    transition: all ease 0.3s;
    &:focus {
      outline: none;
      &.blue {
        border: 1px solid $blue;
      }
      &.orange {
        border: 1px solid $orange;
      }
    }
  }
  .tooltip {
    position: absolute;
    padding: 5px 10px;
    color: #fff;
    background-color: $red;
    border-radius: 5px;
    font-size: 14px;
    right: 0;
    top: 50%;
    transform: translate(calc(100% + 15px), -50%);
    z-index: 100;
    &::after {
      position: absolute;
      content: "";
      width: 8px;
      height: 8px;
      border-radius: 2px;
      background-color: $red;
      left: 0px;
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &.left {
      right: unset;
      left: 0;
      transform: translate(calc(-100% - 15px), -50%);
      &::after {
        left: unset;
        right: 0;
        transform: translate(50%, -50%) rotate(45deg);
      }
    }
  }
}
